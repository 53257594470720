<template>
  <div>
    <portal to="title-page">
      <h1>Crear usuario</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPlus}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.email"
              :rules="emailRules"
              prepend-icon="mdi-email"
              required
              dense
              label="Email*" hint="Ingrese el Email del usuario" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.password"
              :rules="nameRules"
              prepend-icon="mdi-lock"
              required
              dense
              label="Password*" hint="Ingrese el password del usuario" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.name"
              :rules="nameRules"
              prepend-icon="mdi-account"
              required
              dense
              label="Nombre*" hint="Ingrese el nombre del usuario" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="item.lastname"
              :rules="nameRules"
              prepend-icon="mdi-text"
              required
              dense
              label="Apellidos*" hint="Ingrese apellidos del usuario" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-select
                v-model="item.role"
                :items="roles"
                menu-props="auto"
                label="Role"
                hide-details
                prepend-icon="mdi-cog"
                single-line
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-switch
                v-model="item.status"
                inset
                :label="`Status: ${item.status ? 'active' : 'inactive'}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template> 
<script>
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose } from '@mdi/js';

  import firebase from 'firebase/app';
  import 'firebase/auth';
  const axios = require('axios');

  import User from "@/models/User.js";
  export default {
    name: "UserCreate",
    // components: {  },
    data() {
      return {
        ///////////////////////////////////////////////////////
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        validForm:true,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
        nameRules: [
          v => !!v || 'El nombre es obligatorio',
        ],
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
        ],
        item:{
          email: '',
          name: '',
          lastname: '',
          role: 'taquilla',
          status: false
        },
        roles:['admin', 'taquilla'],
        descriptionRules: [
          v => !!v || 'La descripcion es obligatoria',
          v => (v && v.length >= 10) || 'La descripcion debe ser de al menos 10 caracteres',
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        }
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            var config = {
              method: 'post',
              url: process.env.VUE_APP_API_URL+'/api/users/create',
              data: this.item,
              headers:{
                'token':idToken
              }
            };
            axios(config)
            .then(async (response) => {
              // console.log('response', response);
              let model = new User;
              this.item.uid = response.data.uid
              this.item.hasPermissions = false
              model.add(this.item).then(() => {
                loading.hide()
                this.showSnackbarSuccess('El usuario se ha creado correctamente')
                this.$router.go(-1)
              })
            })
            .catch((error) => {
              console.log(error);
              loading.hide()
              if (error.response && error.response.data && error.response.data.error) {
                this.showSnackbarError(error.response.data.error.message)
              } else {
                this.showSnackbarError('El usuario no se ha podido crear, intente de nuevo. Si el problema persiste contacte al Administrador')
              }
            });
          }).catch((error) => {
            // Handle error
            console.log('error al obtener el token', error);
            loading.hide()
            this.showSnackbarError('El usuario no se ha podido crear, intente de nuevo. Si el problema persiste contacte al Administrador')
          });
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    mounted() {
    },
  }
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>